import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { InternshipService } from '../../../service/internship.service';
import { InternshiptypesService } from '../../../service/internshiptypes.service';
import { MentorService } from '../../../service/mentor.service';
import { Internship } from '../../../service/model/internship';
import { Internshipperiod } from '../../../service/model/internshipperiod';
import { Internshiptype } from '../../../service/model/internshiptype';
import { Mentor } from '../../../service/model/mentor';
import { PracticeOrganization } from '../../../service/model/practiceorganization';
import { Student } from '../../../service/model/student';
import { Subjectarea } from '../../../service/model/subjectarea';
import { Trainer } from '../../../service/model/trainer';
import { Uploaddocument } from '../../../service/model/uploaddocument';
import { WorkflowStepExecute } from '../../../service/model/workflow-step-execute';
import { PracticeOrganizationService } from '../../../service/practiceorganization.service';
import { StudentService } from '../../../service/student.service';
import { TrainerService } from '../../../service/trainer.service';
import { UtilsService } from '../../../service/utils.service';
import { ConfirmDialogComponent } from '../../../shared-layout/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '../../../shared-layout/error-dialog/error-dialog.component';
import {
  dateFormat,
  dateFormatForSaving,
  getErrorMessage,
  loadFile,
} from '../../../shared-layout/shared.tools';
import { SharedSettings } from '../../../shared.settings';
import { InternshipperiodSelectDialogComponent } from '../../internshipperiod/internshipperiod-select-dialog/internshipperiod-select-dialog.component';
import { InternshiptypeSelectDialogComponent } from '../../internshiptype/internshiptype-select-dialog/internshiptype-select-dialog.component';
import { MentorSelectDialogComponent } from '../../mentor/mentor-select-dialog/mentor-select-dialog.component';
import { PracticeOrganizationsSelectComponent } from '../../practiceorganization/practiceorganization-select-dialog/practiceorganization-select-dialog.component';
import { StudentSelectDialogComponent } from '../../student/student-select-dialog/student-select-dialog.component';
import { TrainerSelectDialogComponent } from '../../trainer/trainer-select-dialog/trainer-select-dialog.component';
import {
  DeptFieldsInternshipDetail,
  deptFieldsInternshipDetailShowAll,
} from '../internship-fields';
import { RadioGroupEntry } from '../../../service/model/radiogroupentry';
import { InfoService } from '../../../service/info.service';
import { SubjectareaAssignment } from '../../../service/model/subjectarea-assignment';
import { Gradingscale } from '../../../service/model/gradingscale';
import { Gradingscaleservice } from '../../../service/gradingscale.service';

@Component({
  selector: 'app-internship-detail',
  templateUrl: './internship-detail.component.html',
  styleUrls: ['./internship-detail.component.css'],
  providers: [
    InternshipService,
    StudentService,
    PracticeOrganizationService,
    InternshiptypesService,
    TrainerService,
    MentorService,
  ],
})
export class InternshipDetailComponent implements OnInit {
  student = new Student();
  trainer = new Trainer();
  trainerII = new Trainer();
  mentor = new Mentor();
  practiceOrganization = new PracticeOrganization();
  subjectAreas: Subjectarea[];
  selectedSubjectAreas: SubjectareaAssignment[];
  passedStates: RadioGroupEntry[];
  studyStates: RadioGroupEntry[];
  selectedStudyState: RadioGroupEntry;
  internship: Internship;
  isAbgeschlossenOderArchiviert: boolean;
  zmcNotenskala: Gradingscale[];

  loading: boolean;
  loadingInternship: boolean;
  loadingStudent: boolean;
  loadingPassedStates: boolean;
  loadingStudyStates: boolean;
  loadingZmcGrades: boolean;

  isUpdate: boolean;
  error: string;
  submitted = false;

  isDiskreditierungRight = true;

  filtervaluesstudents: '';
  filtervaluestypes: '';
  filtervaluesorganisations: '';
  filtervaluesstudbeg: '';
  filtervaluestrainer: '';

  deptFields: DeptFieldsInternshipDetail;
  departmentId: BehaviorSubject<string>;
  internshipperiodMandatory = false;
  isIHG: boolean;
  canWriteBehaviorSubject: BehaviorSubject<boolean>;

  dataSourceObjectIdErfuellungsstatus = '5B4E951A-E27A-4512-BE12-3FA233C7E512';
  dataSourceObjectIdZMCNotenskala = 'D4AA6092-B127-4549-BD7B-03F82297C10E';
  startDate: Date;
  endDate: Date;

  constructor(
    private route: ActivatedRoute,
    private internshipService: InternshipService,
    private studentService: StudentService,
    private practiceOrganizationService: PracticeOrganizationService,
    private trainerService: TrainerService,
    private mentorService: MentorService,
    private utilsService: UtilsService,
    private dialog: MatDialog,
    private router: Router,
    private infoService: InfoService,
    private gradingscaleservice: Gradingscaleservice
  ) {}

  ngOnInit(): void {
    this.loadingPassedStates = true;
    this.loadingZmcGrades = true;

    this.canWriteBehaviorSubject = this.infoService.getCanWrite();

    this.departmentId = this.infoService.getDepartmentId();
    this.departmentId.subscribe((data) => {
      //console.log(data);
      this.deptFields = this.utilsService.getDeptFields(
        data,
        'internshipDetail'
      );
      //console.log(this.deptFields);
      // if Physio internshipperiod is mandatory
      if (data === '20F0BE6D-E03F-49AC-8DC6-9BBE2C0F80D3') {
        this.internshipperiodMandatory = true;
      }
      // Nur für Testing alle Felder anzeigen
      //this.deptFields = deptFieldsInternshipDetailShowAll;
      if (this.deptFields.selectedStudyState) {
        this.loadingStudyStates = true;
      }

      // Für Hebammen
      if (data === '939118A4-C04B-4A97-98B7-D0369393AD62') {
        this.isIHG = true;
      }

      this.route.params.subscribe((params) => {
        if (params['id'] != 0 && params['id'] != null) {
          this.loadingInternship = true;
          this.isUpdate = true;

          this.internshipService
            .getPassedStates()
            .then((data) => {
              this.passedStates = data;
              this.loadingPassedStates = false;
            })
            .catch((e) => {
              this.loadingPassedStates = false;
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message: 'Die Passed states konnten nicht geladen werden.',
                },
              });
            });

          this.gradingscaleservice
            .getGradingScaleElement(this.dataSourceObjectIdZMCNotenskala)
            .then((items) => {
              this.zmcNotenskala = items;
              this.loadingZmcGrades = false;
            })
            .catch((e) => {
              console.error(e);
              this.loadingZmcGrades = false;
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message: 'Die Zmc Bewertungen konnten nicht geladen werden.',
                },
              });
            });

          this.internshipService
            .getSingle(params['id'])
            .then((data) => {
              this.internship = data;
              //console.log(this.internship);
              if (this.internship.student) {
                this.student = this.internship.student;
              }
              if (this.internship.trainer) {
                this.trainer = this.internship.trainer;
              }
              if (this.internship.trainerII) {
                this.trainerII = this.internship.trainerII;
              }
              if (this.internship.mentor) {
                this.mentor = this.internship.mentor;
              }
              if (this.internship.practiceOrganization) {
                this.practiceOrganization =
                  this.internship.practiceOrganization;
                this.subjectAreas = this.practiceOrganization.subjectAreas
                  .filter(
                    (subjectareaAssignment) =>
                      subjectareaAssignment.selected == true &&
                      subjectareaAssignment.subjectArea.active
                  )
                  .map(
                    (subjectareaAssignment) => subjectareaAssignment.subjectArea
                  );
                this.selectedSubjectAreas = this.internship.subjectAreas;
              }
              this.initInternship();
              this.loadingInternship = false;

              if (this.deptFields.selectedStudyState) {
                this.internshipService
                  .getStudyStates()
                  .then((data) => {
                    this.studyStates = data;
                    this.selectedStudyState = this.studyStates.find(
                      (o) =>
                        o.dataSourceObjectId ==
                        this.internship.selectedStudyState
                    );
                    this.loadingStudyStates = false;
                  })
                  .catch((e) => {
                    this.loadingStudyStates = false;
                    console.error(e);
                    this.dialog.open(ErrorDialogComponent, {
                      data: {
                        message:
                          'Die Studiumstati konnten nicht geladen werden.',
                      },
                    });
                  });
              }
            })
            .catch((e) => {
              this.loadingInternship = false;
              console.error(e);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  message: 'Das Arbeitsverhältnis konnte nicht geladen werden.',
                },
              });
            });
        } else {
          this.isUpdate = false;
          this.internship = new Internship();
          this.internship.planningYear = new Date().getFullYear();
          this.initInternship();
          const studentId = this.route.snapshot.queryParamMap.get('studentId');
          if (studentId !== null) {
            this.loadingStudent = true;

            this.studentService
              .getSingle(studentId)
              .then((data) => {
                const student = data as Student;
                this.setStudent(student);
                this.loadingStudent = false;
              })
              .catch((e) => {
                this.loadingStudent = false;
                console.error(e);
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    message: 'Studierende:r konnte nicht geladen werden.',
                  },
                });
              });
            this.internshipService
              .getPassedStates()
              .then((data) => {
                this.passedStates = data;
                this.loadingPassedStates = false;
              })
              .catch((e) => {
                this.loadingPassedStates = false;
                console.error(e);
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    message: 'Die Passed states konnten nicht geladen werden.',
                  },
                });
              });

            this.gradingscaleservice
              .getGradingScaleElement(this.dataSourceObjectIdZMCNotenskala)
              .then((items) => {
                this.zmcNotenskala = items;
                this.loadingZmcGrades = false;
              })
              .catch((e) => {
                console.error(e);
                this.loadingZmcGrades = false;
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    message:
                      'Die Zmc Bewertungen konnten nicht geladen werden.',
                  },
                });
              });
            if (this.deptFields.selectedStudyState) {
              this.internshipService
                .getStudyStates()
                .then((data) => {
                  this.studyStates = data;
                  this.loadingStudyStates = false;
                })
                .catch((e) => {
                  this.loadingStudyStates = false;
                  console.error(e);
                  this.dialog.open(ErrorDialogComponent, {
                    data: {
                      message: 'Die Studiumstati konnten nicht geladen werden.',
                    },
                  });
                });
            }
          } else {
            console.log(this.internship);
            this.internshipService
              .getPassedStates()
              .then((data) => {
                this.passedStates = data;
                this.loadingPassedStates = false;
              })
              .catch((e) => {
                this.loadingPassedStates = false;
                console.error(e);
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    message: 'Die Passed states konnten nicht geladen werden.',
                  },
                });
              });

            this.gradingscaleservice
              .getGradingScaleElement(this.dataSourceObjectIdZMCNotenskala)
              .then((items) => {
                this.zmcNotenskala = items;
                this.loadingZmcGrades = false;
              })
              .catch((e) => {
                console.error(e);
                this.loadingZmcGrades = false;
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    message:
                      'Die Zmc Bewertungen konnten nicht geladen werden.',
                  },
                });
              });

            if (this.deptFields.selectedStudyState) {
              this.internshipService
                .getStudyStates()
                .then((data) => {
                  this.studyStates = data;
                  this.loadingStudyStates = false;
                })
                .catch((e) => {
                  this.loadingStudyStates = false;
                  console.error(e);
                  this.dialog.open(ErrorDialogComponent, {
                    data: {
                      message: 'Die Studiumstati konnten nicht geladen werden.',
                    },
                  });
                });
            }
          }
        }
      });
    });
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.formInvalid()) {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Es gibt noch leere Pflichtfelder.',
        },
      });
      return;
    }
    this.internship.subjectAreas = this.selectedSubjectAreas;

    this.internship.startdate = dateFormatForSaving(this.startDate);
    this.internship.enddate = dateFormatForSaving(this.endDate);

    if (this.deptFields.selectedStudyState) {
      this.internship.selectedStudyState =
        this.selectedStudyState?.dataSourceObjectId;
    }

    this.loading = true;

    this.internshipService
      .saveInternship(this.internship)
      .then((data) => {
        this.loading = false;

        if (this.internship.id) {
          window.location.reload();
        } else {
          if (data) {
            this.router.navigate(['internship-detail', data.id]);
          }
        }
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Das Arbeitsverhältnis konnte nicht gespeichert werden.',
          },
        });
      });
  }

  initInternship(): void {
    if (!this.internship.internshipPeriod) {
      this.internship.internshipPeriod = new Internshipperiod();
    }
    if (!this.internship.type) {
      this.internship.type = new Internshiptype();
    }
    if (!this.internship.goalSettingDocument) {
      this.internship.goalSettingDocument = new Uploaddocument();
    }
    if (!this.internship.evaluationDocument) {
      this.internship.evaluationDocument = new Uploaddocument();
    }

    if (this.internship.startdate) {
      this.startDate = new Date(Date.parse(this.internship.startdate));
    } else {
      this.startDate = new Date();
    }
    if (this.internship.enddate) {
      this.endDate = new Date(Date.parse(this.internship.enddate));
    } else {
      this.endDate = new Date();
    }

    this.isAbgeschlossenOderArchiviert =
      this.internship.isAbgeschlossenOderArchiviert;
  }

  openStudentDetail(): void {
    if (this.internship.studId) {
      this.router.navigate(['/student-detail', this.internship.studId]);
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Das Arbeitsverhältnis hat keine:n Studierende:n.',
        },
      });
    }
  }

  openStudents(): void {
    const dialogRef = this.dialog.open(StudentSelectDialogComponent, {
      height: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      if (result !== undefined) {
        var student = result as Student;
        this.setStudent(student);
      }
    });
  }

  setStudent(student: Student): void {
    this.loading = true;

    this.studentService
      .getSingle(student.id)
      .then((data) => {
        this.student = data;
        this.internship.student = data;
        this.internship.studId = data.id + '';
        //console.log(this.student);
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Studierende:r konnte nicht geladen werden.',
          },
        });
      });
  }

  openPracticeorganisationDetail(): void {
    if (this.internship.orgId) {
      this.router.navigate(['/practiceorganization', this.internship.orgId]);
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Das Arbeitsverhältnis hat keine Praxisorganisation.',
        },
      });
    }
  }

  openPracticeorganisations(): void {
    const dialogRef = this.dialog.open(PracticeOrganizationsSelectComponent, {
      height: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      if (result !== undefined) {
        var practiceOrganization = result as PracticeOrganization;
        this.setPracticeorganisations(practiceOrganization);
      }
    });
  }

  setPracticeorganisations(practiceOrganization: PracticeOrganization): void {
    this.loading = true;

    this.practiceOrganizationService
      .getSingle(practiceOrganization.id)
      .then((data) => {
        this.practiceOrganization = this.internship.practiceOrganization = data;
        this.internship.orgId = data.id + '';
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Die Praxisorganisation konnte nicht geladen werden.',
          },
        });
      });
  }

  openTrainerDetail(): void {
    if (this.trainer.id) {
      this.router.navigate(['/trainer-detail', this.trainer.id]);
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Das Arbeitsverhältnis hat keine:n Praxisausbildende:n.',
        },
      });
    }
  }

  openTrainers(): void {
    const dialogRef = this.dialog.open(TrainerSelectDialogComponent, {
      height: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      if (result !== undefined) {
        var trainer = result as Trainer;
        this.setTrainer(trainer);
      }
    });
  }

  setTrainer(trainer: Trainer): void {
    this.loading = true;

    this.trainerService
      .getSingle(trainer.id)
      .then((data) => {
        this.trainer = data;
        this.internship.trainer = data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Praxisausbildende:r konnte nicht geladen werden.',
          },
        });
      });
  }

  openTrainerIIDetail(): void {
    if (this.trainerII.id) {
      this.router.navigate(['/trainer-detail', this.trainerII.id]);
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Das Arbeitsverhältnis hat keine:n Praxisausbildende:n II.',
        },
      });
    }
  }

  openTrainersII(): void {
    const dialogRef = this.dialog.open(TrainerSelectDialogComponent, {
      height: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      if (result !== undefined) {
        var trainer = result as Trainer;
        this.setTrainerII(trainer);
      }
    });
  }

  setTrainerII(trainer: Trainer): void {
    this.loading = true;

    this.trainerService
      .getSingle(trainer.id)
      .then((data) => {
        this.trainerII = data;
        this.internship.trainerII = data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Praxisausbildende:r II konnte nicht geladen werden.',
          },
        });
      });
  }

  openMentorDetail(): void {
    if (this.internship.studbegId) {
      this.router.navigate(['/mentor-detail', this.internship.studbegId]);
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message:
            'Das Arbeitsverhältnis hat keine:n Studierendenbegleitende:n.',
        },
      });
    }
  }

  openMentors(): void {
    const dialogRef = this.dialog.open(MentorSelectDialogComponent, {
      height: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      if (result !== undefined) {
        var mentor = result as Mentor;
        this.setMentor(mentor);
      }
    });
  }

  setMentor(mentor: Mentor): void {
    this.loading = true;

    this.mentorService
      .getSingle(mentor.id)
      .then((data) => {
        this.mentor = data;
        this.internship.mentor = data;
        this.internship.studbegId = data.id + '';
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Studierendenbegleitende:r konnte nicht geladen werden.',
          },
        });
      });
  }

  openInternshiptypes(): void {
    const dialogRef = this.dialog.open(InternshiptypeSelectDialogComponent, {
      height: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined) {
        var internshipType = result as Internshiptype;
        this.internship.type = internshipType;

        if (
          this.internship.workload === undefined ||
          this.internship.workload === 0
        ) {
          this.internship.workload = this.internship.type?.workload;
        }

        this.internship.trainingYear = this.internship.type?.trainingYear;
      }
    });
  }

  openInternshiptypeDetail(): void {
    if (this.internship.type?.id) {
      this.router.navigate([
        '/internshiptype-detail',
        this.internship.type?.id,
      ]);
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Das Arbeitsverhältnis hat keinen Praktikumstyp.',
        },
      });
    }
  }

  openInternshipperiods(): void {
    const dialogRef = this.dialog.open(InternshipperiodSelectDialogComponent, {
      height: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
      if (result !== undefined) {
        var internshipperiod = result as Internshipperiod;
        this.internship.internshipPeriod = internshipperiod;
      }
    });
  }

  openInternshipperiodDetail(): void {
    if (this.internship.internshipPeriod?.id) {
      this.router.navigate([
        '/internshipperiod-detail',
        this.internship.internshipPeriod?.id,
      ]);
    } else {
      this.dialog.open(ErrorDialogComponent, {
        data: {
          message: 'Das Arbeitsverhältnis hat keine Praktikumsperiode.',
        },
      });
    }
  }

  onDownloadPublishedGoalSetting(): void {
    loadFile(
      lastValueFrom(this.internshipService.getGoalSetting(this.internship.id)),
      'application/pdf',
      this.internship.goalSettingDocument.headOfFilename,
      (error) => {
        this.error = getErrorMessage(error);
      }
    );
  }

  onDownloadPublishedEvaluation(): void {
    loadFile(
      lastValueFrom(this.internshipService.getEvaluation(this.internship.id)),
      'application/pdf',
      this.internship.evaluationDocument.headOfFilename,
      (error) => {
        this.error = getErrorMessage(error);
      }
    );
  }

  onChangeDocument(event: any, document: Uploaddocument): void {
    const file = event.target.files[0];

    if (file.name.endsWith(SharedSettings.upload.fileExtension)) {
      if (file.size <= SharedSettings.upload.fileSize) {
        const reader = new FileReader();
        reader.onload = () => {
          document.headOfBase64 = reader.result;
          document.headOfBase64Override = true;
          document.headOfFilename = file.name;
        };
        reader.readAsDataURL(file);
      } else {
        document.headOfBase64 = null;
        document.headOfBase64Override = false;
        document.headOfFilename = null;

        this.dialog.open(ErrorDialogComponent, {
          data: {
            message:
              'Die Datei ist zu gross. Bitte wählen Sie eine PDF-Datei welche maximal 10 MB gross ist.',
          },
        });
      }
    } else {
      document.headOfBase64 = null;
      document.headOfBase64Override = false;
      document.headOfFilename = null;

      this.dialog.open(ErrorDialogComponent, {
        data: {
          message:
            'Es sind nur PDF Dateien zugelassen. Bitte prüfen Sie die zum hochladen gewählte Datei.',
        },
      });
    }
  }

  getHeadOfGoalSettingDocumentLabel(): string {
    if (this.internship.goalSettingDocument) {
      return this.internship.goalSettingDocument.headOfFilename;
    } else {
      return 'Keine Datei gewählt';
    }
  }

  getEvaluationDocumentLabel(): string {
    if (this.internship.evaluationDocument?.headOfFilename) {
      return this.internship.evaluationDocument.headOfFilename;
    } else {
      return 'Keine Datei gewählt';
    }
  }

  setStartEnd(): void {
    this.loading = true;

    this.internshipService
      .calcStartEndDate(this.internship)
      .then((data) => {
        this.internship = data;
        this.initInternship();
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Start und Ende konnten nicht gesetzt werden.',
          },
        });
      });
  }

  setGradeDateAndDiscretization(): void {
    this.internship.gradeDate = new Date().toISOString().split('T')[0];
    //check discretization
    const grade = Number.parseFloat(this.internship.grade);
    const discretization = Number.parseFloat(
      this.internship.type.gradingScale.discretization
    );
    if (Number.isNaN(grade)) {
      this.isDiskreditierungRight = false;
    } else {
      this.isDiskreditierungRight =
        Number.isNaN(discretization) || (grade / discretization) % 1 === 0;
    }
  }

  getDiscretization(): string {
    if (this.internship.type && this.internship.type.gradingScale) {
      return this.internship.type.gradingScale.discretization;
    } else {
      return '';
    }
  }

  setPubliziert(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: 'Soll dieses Arbeitsverhältnis publiziert werden?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const workflowStepExecute: WorkflowStepExecute = {
          internships: [this.internship],
          dataSourceObjectIdStep: 'C8912365-720D-41A9-8E41-FCFE195D60F9',
          reverse: false,
        };

        this.loading = true;

        this.internshipService
          .executeWorkflowStep(workflowStepExecute)
          .then((data) => {
            window.location.reload();
            this.internship = data[0];
            this.initInternship();
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Das Arbeitsverhältnis konnte nicht publiziert werden.',
              },
            });
          });
      }
    });
  }

  setInPlanung(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message:
          'Soll dieses Arbeitsverhältnis zurück In Planung gesetzt werden?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const workflowStepExecute: WorkflowStepExecute = {
          internships: [this.internship],
          dataSourceObjectIdStep: 'C8912365-720D-41A9-8E41-FCFE195D60F9',
          reverse: true,
        };

        this.loading = true;

        this.internshipService
          .executeWorkflowStep(workflowStepExecute)
          .then((data) => {
            this.internship = data[0];
            this.initInternship();
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.error(e);
            this.dialog.open(ErrorDialogComponent, {
              data: {
                message:
                  'Das Arbeitsverhältnis konnte nicht zurück In Planung gesetzt werden.',
              },
            });
          });
      }
    });
  }

  formInvalid(): boolean {
    return (
      this.internship.student === undefined ||
      this.internship.student?.id === undefined ||
      this.internship.student?.id === '' ||
      this.internship.type === undefined ||
      this.internship.type?.id === undefined ||
      this.internship.type?.id === null ||
      (this.internshipperiodMandatory &&
        this.internship.internshipPeriod?.id === undefined)
    );
  }

  zeitplanungBerechnen(): void {
    this.loading = true;

    this.internshipService
      .getCalcTimePlanning(this.internship)
      .then((data) => {
        this.internship = data;
        this.initInternship();
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.error(e);
        this.dialog.open(ErrorDialogComponent, {
          data: {
            message: 'Die Zeitplanung konnte nicht berechnet werden.',
          },
        });
      });
  }

  checkWorkingHours(): void {
    this.internship.workingHours = Math.trunc(this.internship.workingHours);
    //console.log(this.internship.workingHours);
  }
}
